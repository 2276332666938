import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Link,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { Form, Formik, useFormikContext } from "formik";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { FormHeader } from "../../components";
import { FormFieldLabel } from "../../componentsV2/FormFieldLabel";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";
import {
  useUpdateSustainabilityForm,
  useGetSustainabilityData,
  useMetadata,
  useSubmitSustainabilityForm,
  SustainabilityDataQueryKey,
} from "../../hooks";
import {
  SustainabilityFormResponse,
  SustainabilityFormSubmitData,
  SustainabilityReferenceTopic,
  SustainabilityReferenceType,
  SUSTAINABILITY_REFERENCE_TOPICS,
  SUSTAINABILITY_REFERENCE_TYPES,
} from "../../types/types";
import theme from "../../utils/theme";

const requiredFieldMessage = "This is a required field";

const schema = Yup.object({
  // this is used for context - some fields won't be required if the form
  // is being filled in for a special with no episodes
  numberOfEpisodes: Yup.number().defined(),
  verbalReferences: Yup.string()
    .required(requiredFieldMessage)
    .oneOf(SUSTAINABILITY_REFERENCE_TYPES),
  verbalReferencesEpisodes: Yup.array()
    .of(Yup.number().required())
    .when(
      ["$verbalReferences", "$numberOfEpisodes"],
      ([verbalReferences, numberOfEpisodes], schema) => {
        if ((verbalReferences as SustainabilityReferenceType) === "NONE") {
          return schema;
        }

        // the question is only shown and therefore only required if there are episodes
        if (numberOfEpisodes === 0) {
          return schema;
        }

        return schema.min(1, requiredFieldMessage);
      },
    )
    .defined(),
  verbalReferencesTopics: Yup.array()
    .of(Yup.string().required().oneOf(SUSTAINABILITY_REFERENCE_TOPICS))
    .when("$verbalReferences", ([verbalReferences], schema) => {
      if ((verbalReferences as SustainabilityReferenceType) === "NONE") {
        return schema;
      }
      return schema.min(1, requiredFieldMessage);
    })
    .required(),
  verbalReferencesTopicsDetails: Yup.string()
    .when("$verbalReferencesTopics", ([verbalReferencesTopics], schema) => {
      if (verbalReferencesTopics && verbalReferencesTopics.includes("OTHER")) {
        return schema.required(requiredFieldMessage);
      }

      return schema.optional().default("");
    })
    .defined(),
  visualReferences: Yup.boolean()
    .required(requiredFieldMessage)
    .defined(requiredFieldMessage),
  visualReferencesEpisodes: Yup.array()
    .of(Yup.number().required())
    .when(
      ["$visualReferences", "$numberOfEpisodes"],
      ([visualReferences, numberOfEpisodes], schema) => {
        if (!visualReferences) {
          return schema;
        }

        // the question is only shown and therefore only required if there are episodes
        if (numberOfEpisodes === 0) {
          return schema;
        }

        return schema.min(1, requiredFieldMessage);
      },
    )
    .defined(),
  visualReferencesTopics: Yup.array()
    .of(Yup.string().required().oneOf(SUSTAINABILITY_REFERENCE_TOPICS))
    .when("$visualReferences", ([visualReferences], schema) => {
      if (!visualReferences) {
        return schema;
      }
      return schema.min(1, requiredFieldMessage);
    })
    .defined(),
  visualReferencesTopicsDetails: Yup.string()
    .when("$visualReferencesTopics", ([visualReferencesTopics], schema) => {
      if (visualReferencesTopics && visualReferencesTopics.includes("OTHER")) {
        return schema.required(requiredFieldMessage);
      }

      return schema.optional().default("");
    })
    .defined(),
  examples: Yup.string().required(requiredFieldMessage),
});

type SustainabilityFormInitialValues = Omit<
  Yup.InferType<typeof schema>,
  "verbalReferences" | "visualReferences"
> & {
  visualReferences: boolean | undefined;
  verbalReferences: SustainabilityReferenceType | undefined;
};

const verbalReferencesOptions: Array<{
  label: string;
  value: SustainabilityReferenceType;
}> = [
  {
    label: "Yes, it’s the main editorial focus or theme of the production",
    value: "MAIN_FOCUS",
  },
  {
    label: "Yes, it’s part of a sub-plot, secondary storyline or short item",
    value: "SUB_PLOT",
  },
  {
    label: "Yes, it’s mentioned in passing",
    value: "IN_PASSING",
  },
  {
    label: "No, there are no verbal references",
    value: "NONE",
  },
];

const visualReferencesOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const referenceTopicsOptions: Array<{
  label: React.ReactNode;
  value: SustainabilityReferenceTopic;
}> = [
  {
    label: (
      <>
        <strong>Impacts</strong> of Climate Change or Depiction of Climate
        Science/Nature Loss
      </>
    ),
    value: "IMPACTS",
  },
  {
    label: (
      <>
        <strong>Protection from or Adapting to</strong> Nature Loss or Changes
        in Climate
      </>
    ),
    value: "PROTECTION",
  },
  {
    label: (
      <>
        Sustainable <strong>Travel</strong>
      </>
    ),
    value: "SUSTAINABLE_TRAVEL",
  },
  {
    label: (
      <>
        Sustainable <strong>Energy</strong> and Power
      </>
    ),
    value: "SUSTAINABLE_ENERGY",
  },
  {
    label: (
      <>
        <strong>Food</strong>, Sustainable Land Use or Nature Restoration
      </>
    ),
    value: "FOOD",
  },
  {
    label: (
      <>
        Sustainable Materials/Resource Use and{" "}
        <strong>Avoiding/Reducing Waste</strong>
      </>
    ),
    value: "SUSTAINABLE_MATERIALS",
  },
  {
    label: (
      <>
        <strong>Sustainable Investment</strong> & Economics
      </>
    ),
    value: "SUSTAINABLE_INVESTMENT",
  },
  {
    label: (
      <>
        <strong>Imaginings</strong>, Discussions, Actions or Demands for a
        Sustainable World
      </>
    ),
    value: "IMAGININGS",
  },
  {
    label: (
      <>
        Personal <strong>Connection with Nature</strong>
      </>
    ),
    value: "CONNECTION_WITH_NATURE",
  },
  {
    label: (
      <>
        <strong>Behaviours/Industries that are Causes</strong> of Climate Change
        and Nature Loss
      </>
    ),
    value: "BEHAVIOURS",
  },
  {
    label: (
      <>
        <strong>Celeb</strong>/Influencer/Public Figure{" "}
        <strong>Endorsement</strong> of Green Behaviours
      </>
    ),
    value: "CELEB_ENDORSEMENT",
  },
  {
    label: (
      <>
        <strong>Other</strong> (please specify)
      </>
    ),
    value: "OTHER",
  },
];

export function SustainabilityForm() {
  const { seriesCcid, titleCcid } = useParams();
  const ccid = seriesCcid || titleCcid;

  const {
    data: sustainabilityResponse,
    isLoading: loadingSustainabilityData,
    error: sustainabilityError,
  } = useGetSustainabilityData(ccid);

  const { mutate: submitForm, isLoading: isSubmitting } =
    useSubmitSustainabilityForm();

  const { data: metadata, isLoading: loadingMetadata } = useMetadata(ccid);

  const isLoading = loadingSustainabilityData || loadingMetadata;

  if (isLoading) {
    return (
      <>
        <FormHeader formTitle="On-Screen Sustainability Form" />
        <Stack alignItems="center" m={4}>
          <CircularProgress />
        </Stack>
      </>
    );
  }

  if (!sustainabilityResponse) {
    if (
      !axios.isAxiosError(sustainabilityError) ||
      !sustainabilityError.response ||
      sustainabilityError.response.status !== 404
    ) {
      return (
        <>
          <FormHeader formTitle="Close-down Form" />
          <Stack alignItems="center" m={4}>
            <Alert severity="error">
              There was an issue retrieving the data for this form. Please try
              refreshing or contact us if the issue persists
            </Alert>
          </Stack>
        </>
      );
    }
  }

  if (!metadata) {
    return (
      <>
        <FormHeader formTitle="Close-down Form" />
        <Stack alignItems="center" m={4}>
          <Alert severity="error">
            There was an issue retrieving the data for this form. Please try
            refreshing or contact us if the issue persists
          </Alert>
        </Stack>
      </>
    );
  }

  const sustainabilityData = sustainabilityResponse?.data;

  const numberOfEpisodes = metadata.episodeCount || 0;

  const initialValues: SustainabilityFormInitialValues = {
    numberOfEpisodes,

    verbalReferences: sustainabilityData?.verbalReferences || undefined,
    verbalReferencesEpisodes:
      sustainabilityData?.verbalReferencesEpisodes || [],
    verbalReferencesTopics: sustainabilityData?.verbalReferencesTopics || [],
    verbalReferencesTopicsDetails:
      sustainabilityData?.verbalReferencesTopicsDetails || "",

    visualReferences: sustainabilityData?.visualReferences ?? undefined,
    visualReferencesEpisodes:
      sustainabilityData?.visualReferencesEpisodes || [],
    visualReferencesTopics: sustainabilityData?.visualReferencesTopics || [],
    visualReferencesTopicsDetails:
      sustainabilityData?.visualReferencesTopicsDetails || "",

    examples: sustainabilityData?.examples || "",
  };

  return (
    <>
      <FormHeader formTitle="On-Screen Sustainability Form" />
      <Stack alignItems="center" m={4}>
        <Container disableGutters>
          <Formik<SustainabilityFormInitialValues>
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={(values) => {
              submitForm(
                {
                  ccid: ccid as string,
                  // we can cast the type here as the `onSubmit` only fires after successful validation
                  formData: values as Yup.InferType<typeof schema>,
                },
                {
                  onSuccess: () => {
                    enqueueSnackbar("Form successfully submitted!", {
                      variant: "success",
                    });
                  },
                  onError: () => {
                    enqueueSnackbar(
                      "There was an error submitting the form. Please try again and contact us if this persists",
                      {
                        variant: "error",
                        action: SnackbarDismiss,
                        persist: true,
                      },
                    );
                  },
                },
              );
            }}
          >
            <Form noValidate>
              <SustainabilityFormFields
                ccid={ccid as string}
                numberOfEpisodes={numberOfEpisodes}
                autosaveDate={sustainabilityData?.modifiedTime || null}
                isSubmitting={isSubmitting}
              />
            </Form>
          </Formik>
        </Container>
      </Stack>
    </>
  );
}

function SustainabilityFormFields({
  numberOfEpisodes,
  ccid,
  autosaveDate,
  isSubmitting,
}: {
  numberOfEpisodes: number;
  ccid: string;
  autosaveDate: string | null;
  isSubmitting: boolean;
}) {
  const formik = useFormikContext<SustainabilityFormInitialValues>();

  const { mutate: updateForm, isLoading: isAutosaving } =
    useUpdateSustainabilityForm();

  const { status: sustainabilityStatus } = useGetSustainabilityData(ccid);

  const queryClient = useQueryClient();

  const referenceEpisodeOptions = Array.from(
    { length: numberOfEpisodes },
    (_, i) => i + 1,
  );

  function autosaveForm(formData: Partial<SustainabilityFormSubmitData>) {
    updateForm(
      { ccid, formData },
      {
        onSuccess: () => {
          enqueueSnackbar("Form successfully autosaved!", {
            variant: "success",
          });

          // only overwrite the cache if something is already there
          if (sustainabilityStatus === "success") {
            const queryKey: SustainabilityDataQueryKey = [
              "sustainabilityData",
              ccid,
            ];

            queryClient.setQueryData(
              queryKey,
              (formData: SustainabilityFormResponse | undefined) => {
                if (!formData) {
                  return undefined as unknown as SustainabilityFormResponse;
                }

                return {
                  data: {
                    ...formData.data,
                    modifiedTime: new Date().toISOString(),
                  },
                };
              },
            );
          }
        },
        onError: () => {
          enqueueSnackbar(
            "There was an error autosaving the form. Please try again and contact us if this persists",
            {
              variant: "error",
              action: SnackbarDismiss,
              persist: true,
            },
          );
        },
      },
    );
  }

  const values = formik.values;
  const errors = formik.errors;

  const shouldDisplayErrors = formik.submitCount > 0;

  return (
    <Stack alignItems="left" spacing={5} direction="row">
      <Box sx={{ maxWidth: "100%", p: 1, position: "sticky" }}>
        <FormSidebar
          autosaveDate={autosaveDate}
          isSubmitting={isSubmitting}
          isAutosaving={isAutosaving}
          hasErrors={shouldDisplayErrors && !formik.isValid}
        />
      </Box>
      <Box width="100%">
        <Stack spacing={2}>
          <Stack direction="column" spacing={3} py={1}>
            <Card>
              <CardContent>
                <Stack spacing={4}>
                  <Stack spacing={2}>
                    <Typography>
                      We want to make shows with the biggest impact on audiences
                      and the smallest impact on the planet. That means
                      increasing the amount of climate action content on-screen
                      across all genres, and reducing emissions and waste in our
                      productions.
                    </Typography>
                    <Typography>
                      ITV has made a{" "}
                      <Link href="https://www.itvplc.com/social-purpose/climate-action">
                        Climate Content Pledge
                      </Link>
                      , committing to increasing the amount of content on-screen
                      that supports the transition to a more sustainable world.
                      We ask all shows to embed some type of content, and track
                      the result. For more information, please see the
                      accompanying help document on the{" "}
                      <Link href="https://www.itv.com/commissioning">
                        ITV Commissioning
                      </Link>{" "}
                      webpage before completing the below form.
                    </Typography>
                  </Stack>

                  <FormControl required={true}>
                    <Box display="inline-flex" alignItems="center">
                      <FormLabel
                        sx={{
                          marginBottom: "16px",
                          "& .MuiFormLabel-asterisk": {
                            color: theme.palette.error.main,
                          },
                        }}
                        id="verbalReferences-label"
                      >
                        Do <strong>verbal references</strong> to climate change,
                        sustainability, the environment or nature feature in the
                        dialogue or commentary of the programme?
                      </FormLabel>
                    </Box>

                    <RadioGroup
                      id="verbalReferences"
                      name="verbalReferences"
                      value={values.verbalReferences || ""}
                      aria-labelledby="verbalReferences-label"
                      aria-describedby="verbalReferences-error"
                      sx={{ width: "80%", paddingLeft: "24px" }}
                    >
                      {verbalReferencesOptions.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          label={option.label}
                          value={option.value}
                          control={
                            <Radio
                              onChange={(event) => {
                                formik.handleChange(event);
                                autosaveForm({
                                  verbalReferences: option.value,
                                });
                              }}
                              onBlur={formik.handleBlur}
                            />
                          }
                        />
                      ))}
                    </RadioGroup>

                    {shouldDisplayErrors && errors.verbalReferences ? (
                      <FormHelperText
                        id="verbalReferences-error"
                        error={true}
                        sx={{ margin: 0 }}
                      >
                        {errors.verbalReferences}
                      </FormHelperText>
                    ) : null}
                  </FormControl>

                  {Boolean(values.verbalReferences) &&
                  values.verbalReferences !== "NONE" &&
                  referenceEpisodeOptions.length > 0 ? (
                    <FormFieldLabel
                      inputId="verbalReferencesEpisodes"
                      label="Which episode(s) feature this content? Please select all that apply"
                      required={true}
                    >
                      <Autocomplete
                        id="verbalReferencesEpisodes"
                        multiple={true}
                        fullWidth
                        value={values.verbalReferencesEpisodes}
                        onChange={(_, selectedValues) => {
                          formik
                            .getFieldHelpers("verbalReferencesEpisodes")
                            .setValue(selectedValues);

                          autosaveForm({
                            verbalReferencesEpisodes: selectedValues,
                          });
                        }}
                        onBlur={formik.handleBlur}
                        aria-describedby="verbalReferencesEpisodes-error"
                        options={referenceEpisodeOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="verbalReferencesEpisodes"
                            required={true}
                            error={
                              shouldDisplayErrors &&
                              Boolean(errors.verbalReferencesEpisodes)
                            }
                            aria-describedby="verbalReferencesEpisodes-error"
                          />
                        )}
                        getOptionLabel={(option) => {
                          return `Episode ${option}`;
                        }}
                      />

                      {shouldDisplayErrors &&
                      errors.verbalReferencesEpisodes ? (
                        <FormHelperText
                          id="verbalReferencesEpisodes-error"
                          error={true}
                        >
                          {errors.verbalReferencesEpisodes}
                        </FormHelperText>
                      ) : null}
                    </FormFieldLabel>
                  ) : null}

                  {Boolean(values.verbalReferences) &&
                  values.verbalReferences !== "NONE" ? (
                    <FormControl required={true} component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          marginBottom: "16px",
                          "& .MuiFormLabel-asterisk": {
                            color: theme.palette.error.main,
                          },
                        }}
                      >
                        Which of the following topics does the reference(s)
                        relate to? It could be about individual choices and
                        experiences, policy or wider social change. Please
                        select all that apply
                      </FormLabel>

                      <FormGroup
                        aria-describedby="verbalReferencesTopics-error"
                        sx={{ width: "80%", paddingLeft: "24px" }}
                      >
                        {referenceTopicsOptions.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            label={option.label}
                            value={option.value}
                            control={
                              <Checkbox
                                checked={values.verbalReferencesTopics.includes(
                                  option.value,
                                )}
                                onChange={(_, checked) => {
                                  const newValues = checked
                                    ? values.verbalReferencesTopics
                                        .slice()
                                        .concat(option.value)
                                    : values.verbalReferencesTopics.filter(
                                        (val) => val !== option.value,
                                      );

                                  formik
                                    .getFieldHelpers("verbalReferencesTopics")
                                    .setValue(newValues);

                                  autosaveForm({
                                    verbalReferencesTopics: newValues,
                                  });
                                }}
                              />
                            }
                          />
                        ))}
                      </FormGroup>

                      {shouldDisplayErrors && errors.verbalReferencesTopics ? (
                        <FormHelperText
                          id="verbalReferencesTopics-error"
                          error={true}
                          sx={{ margin: 0 }}
                        >
                          {errors.verbalReferencesTopics}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  ) : null}

                  {values.verbalReferencesTopics.includes("OTHER") ? (
                    <FormFieldLabel
                      inputId="verbalReferencesTopicsDetails"
                      label="Please detail other referenced topics"
                      required={true}
                    >
                      <OutlinedInput
                        type="text"
                        id="verbalReferencesTopicsDetails"
                        name="verbalReferencesTopicsDetails"
                        required={true}
                        multiline={true}
                        rows={5}
                        fullWidth
                        value={values.verbalReferencesTopicsDetails || ""}
                        onChange={formik.handleChange}
                        onBlur={(event) => {
                          formik.handleBlur(event);
                          autosaveForm({
                            verbalReferencesTopicsDetails: event.target.value,
                          });
                        }}
                        aria-describedby="verbalReferencesTopicsDetails-error"
                        error={
                          shouldDisplayErrors &&
                          Boolean(errors.verbalReferencesTopicsDetails)
                        }
                        placeholder="Verbally referenced topics"
                      />
                      {shouldDisplayErrors &&
                      errors.verbalReferencesTopicsDetails ? (
                        <FormHelperText
                          id="verbalReferencesTopicsDetails-error"
                          error={true}
                        >
                          {errors.verbalReferencesTopicsDetails}
                        </FormHelperText>
                      ) : null}
                    </FormFieldLabel>
                  ) : null}

                  <FormControl required={true}>
                    <Box display="inline-flex" alignItems="center">
                      <FormLabel
                        sx={{
                          marginBottom: "16px",
                          "& .MuiFormLabel-asterisk": {
                            color: theme.palette.error.main,
                          },
                        }}
                        id="visualReferences-label"
                      >
                        Are there any <strong>visual references</strong>, such
                        as props, backgrounds or actions, that relate to climate
                        change, sustainability, the environment or nature
                        displayed on screen?
                      </FormLabel>
                    </Box>

                    <RadioGroup
                      id="visualReferences"
                      name="visualReferences"
                      value={values.visualReferences ?? ""}
                      aria-labelledby="visualReferences-label"
                      aria-describedby="visualReferences-error"
                      sx={{ width: "80%", paddingLeft: "24px" }}
                    >
                      {visualReferencesOptions.map((option) => (
                        <FormControlLabel
                          key={String(option.value)}
                          label={option.label}
                          value={option.value}
                          control={
                            <Radio
                              onChange={() => {
                                formik
                                  .getFieldHelpers("visualReferences")
                                  .setValue(option.value);

                                autosaveForm({
                                  visualReferences: option.value,
                                });
                              }}
                              onBlur={formik.handleBlur}
                            />
                          }
                        />
                      ))}
                    </RadioGroup>

                    {shouldDisplayErrors && errors.visualReferences ? (
                      <FormHelperText
                        id="visualReferences-error"
                        error={true}
                        sx={{ margin: 0 }}
                      >
                        {errors.visualReferences}
                      </FormHelperText>
                    ) : null}
                  </FormControl>

                  {values.visualReferences === true &&
                  referenceEpisodeOptions.length > 0 ? (
                    <FormFieldLabel
                      inputId="visualReferencesEpisodes"
                      label="Which episode(s) feature this content? Please select all that apply"
                      required={true}
                    >
                      <Autocomplete
                        id="visualReferencesEpisodes"
                        multiple={true}
                        fullWidth
                        value={values.visualReferencesEpisodes}
                        onChange={(_, selectedValues) => {
                          formik
                            .getFieldHelpers("visualReferencesEpisodes")
                            .setValue(selectedValues);

                          autosaveForm({
                            visualReferencesEpisodes: selectedValues,
                          });
                        }}
                        onBlur={formik.handleBlur}
                        aria-describedby="visualReferencesEpisodes-error"
                        options={referenceEpisodeOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="visualReferencesEpisodes"
                            required={true}
                            error={
                              shouldDisplayErrors &&
                              Boolean(errors.visualReferencesEpisodes)
                            }
                            aria-describedby="visualReferencesEpisodes-error"
                          />
                        )}
                        getOptionLabel={(option) => {
                          return `Episode ${option}`;
                        }}
                      />

                      {shouldDisplayErrors &&
                      errors.visualReferencesEpisodes ? (
                        <FormHelperText
                          id="visualReferencesEpisodes-error"
                          error={true}
                        >
                          {errors.visualReferencesEpisodes}
                        </FormHelperText>
                      ) : null}
                    </FormFieldLabel>
                  ) : null}

                  {values.visualReferences === true ? (
                    <FormControl required={true} component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          marginBottom: "16px",
                          "& .MuiFormLabel-asterisk": {
                            color: theme.palette.error.main,
                          },
                        }}
                      >
                        Which topic(s) does the sustainability editorial relate
                        to? It could be about personal choices and experiences
                        or policy/society-wide change. Please select all that
                        apply.
                      </FormLabel>

                      <FormGroup
                        aria-describedby="visualReferencesTopics-error"
                        sx={{ width: "80%", paddingLeft: "24px" }}
                      >
                        {referenceTopicsOptions.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            label={option.label}
                            value={option.value}
                            control={
                              <Checkbox
                                checked={values.visualReferencesTopics.includes(
                                  option.value,
                                )}
                                onChange={(_, checked) => {
                                  const newValues = checked
                                    ? values.visualReferencesTopics
                                        .slice()
                                        .concat(option.value)
                                    : values.visualReferencesTopics.filter(
                                        (val) => val !== option.value,
                                      );

                                  formik
                                    .getFieldHelpers("visualReferencesTopics")
                                    .setValue(newValues);

                                  autosaveForm({
                                    visualReferencesTopics: newValues,
                                  });
                                }}
                              />
                            }
                          />
                        ))}
                      </FormGroup>

                      {shouldDisplayErrors && errors.visualReferencesTopics ? (
                        <FormHelperText
                          id="visualReferencesTopics-error"
                          error={true}
                          sx={{ margin: 0 }}
                        >
                          {errors.visualReferencesTopics}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  ) : null}

                  {values.visualReferencesTopics.includes("OTHER") ? (
                    <FormFieldLabel
                      inputId="visualReferencesTopicsDetails"
                      label="Please detail other referenced topics"
                      required={true}
                    >
                      <OutlinedInput
                        type="text"
                        id="visualReferencesTopicsDetails"
                        name="visualReferencesTopicsDetails"
                        required={true}
                        multiline={true}
                        rows={5}
                        fullWidth
                        value={values.visualReferencesTopicsDetails || ""}
                        onChange={formik.handleChange}
                        onBlur={(event) => {
                          formik.handleBlur(event);
                          autosaveForm({
                            visualReferencesTopicsDetails: event.target.value,
                          });
                        }}
                        aria-describedby="visualReferencesTopicsDetails-error"
                        error={
                          shouldDisplayErrors &&
                          Boolean(errors.visualReferencesTopicsDetails)
                        }
                        placeholder="Visually referenced topics"
                      />
                      {shouldDisplayErrors &&
                      errors.visualReferencesTopicsDetails ? (
                        <FormHelperText
                          id="visualReferencesTopicsDetails-error"
                          error={true}
                        >
                          {errors.visualReferencesTopicsDetails}
                        </FormHelperText>
                      ) : null}
                    </FormFieldLabel>
                  ) : null}

                  <FormFieldLabel
                    inputId="examples"
                    label="Please provide specific examples of these on-screen dialogue, commentary or visual references, including timecodes, quotes and any other comments"
                    required={true}
                  >
                    <OutlinedInput
                      type="text"
                      id="examples"
                      name="examples"
                      required={true}
                      multiline={true}
                      rows={5}
                      fullWidth
                      value={values.examples || ""}
                      onChange={formik.handleChange}
                      onBlur={(event) => {
                        formik.handleBlur(event);
                        autosaveForm({ examples: event.target.value });
                      }}
                      aria-describedby="examples-error"
                      error={shouldDisplayErrors && Boolean(errors.examples)}
                      placeholder="Dialogue, quotes, etc."
                    />
                    {shouldDisplayErrors && errors.examples ? (
                      <FormHelperText id="examples-error" error={true}>
                        {errors.examples}
                      </FormHelperText>
                    ) : null}
                  </FormFieldLabel>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}

function FormSidebar({
  autosaveDate,
  isSubmitting,
  isAutosaving,
  hasErrors,
}: {
  autosaveDate: string | null;
  isSubmitting: boolean;
  isAutosaving: boolean;
  hasErrors: boolean;
}) {
  return (
    <Box position="sticky" top={0} padding={1} width="270px">
      <Stack spacing={2}>
        {hasErrors ? (
          <Alert severity="error">
            This form has errors. Please fill in all required fields before
            submitting
          </Alert>
        ) : null}
        {autosaveDate ? (
          <Box display="flex" alignItems="center">
            <Typography sx={{ fontSize: "14px", fontWeight: "light" }}>
              Autosaved: {dayjs(autosaveDate).format("DD/MM/YYYY HH:mm a")}
            </Typography>

            <Box
              component="span"
              paddingLeft="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress
                size="16px"
                sx={{ visibility: isAutosaving ? "visibile" : "hidden" }}
              />
            </Box>
          </Box>
        ) : null}

        <LoadingButton
          type="submit"
          loading={isSubmitting}
          variant="contained"
          size="large"
          color="primary"
        >
          Submit Form
        </LoadingButton>
      </Stack>
    </Box>
  );
}
