import Timecode from "smpte-timecode";

export const FRAME_RATE = 24;

export const convertTimeCodeToSeconds = (timeCode: string): number => {
  const arr = timeCode.split(":");

  const [hours = 0, minutes = 0, seconds = 0, frames = 0] = arr.map(Number);
  return hours * 3600 + minutes * 60 + seconds + frames / FRAME_RATE;
};

export const digitToStr = (digit: number): string => {
  const isNegative = digit < 0;
  const absValue = Math.abs(digit);

  const result = absValue < 10 ? `0${absValue}` : `${absValue}`;
  return isNegative ? `-${result}` : result;
};

export const convertSecondsToTimeCode = (totalSeconds: number): string => {
  const func = totalSeconds < 0 ? Math.ceil : Math.floor;
  const oneHour = 3600;
  const oneMinute = 60;

  const hours = func(totalSeconds / oneHour);
  const minutes = func((totalSeconds % oneHour) / oneMinute);
  const seconds = func(totalSeconds % oneMinute);
  const frames = func((totalSeconds - func(totalSeconds)) * FRAME_RATE);

  return `${digitToStr(hours)}:${digitToStr(minutes)}:${digitToStr(seconds)}:${digitToStr(frames)}`;
};

export const isValidTimecode = (timecode: string | undefined): boolean => {
  try {
    Timecode(timecode, FRAME_RATE);
  } catch (e) {
    return false;
  }
  return true;
};
