import { useQuery } from "react-query";
import { getSustainabilityData } from "../../api/legalDeliverables";

export type SustainabilityDataQueryKey = ["sustainabilityData", string];

export function useGetSustainabilityData(ccid: string | undefined) {
  const queryKey: SustainabilityDataQueryKey = [
    "sustainabilityData",
    ccid as string,
  ];

  return useQuery(
    queryKey,
    () => {
      return getSustainabilityData(ccid as string);
    },
    {
      enabled: Boolean(ccid),
    },
  );
}
