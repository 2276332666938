import { useMutation } from "react-query";
import { updateSustainabilityForm } from "../../api/legalDeliverables";
import { SustainabilityFormSubmitData } from "../../types/types";

export function useUpdateSustainabilityForm() {
  return useMutation(
    ({
      ccid,
      formData,
    }: {
      ccid: string;
      formData: Partial<SustainabilityFormSubmitData>;
    }) => {
      return updateSustainabilityForm({ ccid, formData });
    },
  );
}
