/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useMemo, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ContributorProps } from "../../../../../../types/types";
import { getCreditId } from "../../contributor.helper";

interface ContributorsTableProps {
  displayCheckbox: boolean;
  contributors: ContributorProps[];
  onChange(ids: any[]): void;
}

const PAGE_SIZE = 100;

export const ContributorsTable: FC<ContributorsTableProps> = ({
  displayCheckbox,
  contributors,
  onChange,
}) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const columns = useMemo(
    () => [
      {
        field: "forenames",
        headerName: "Forenames",
        width: 200,
      },
      {
        field: "surname",
        headerName: "Surname",
        width: 200,
      },
      {
        field: "role",
        headerName: "Role",
        width: 250,
      },
      {
        field: "character",
        headerName: "character",
        width: 200,
      },
    ],
    [],
  );

  useEffect(() => {
    const newRowData: any[] = [];

    if (contributors) {
      const contributorIds: string[] = [];
      contributors.forEach((contributor: ContributorProps) => {
        const creditId = getCreditId(contributor);
        if (!contributorIds.includes(creditId)) {
          newRowData.push({
            ...contributor,
            role:
              typeof contributor.role === "string"
                ? contributor.role
                : contributor.role?.displayName,
          });

          contributorIds.push(creditId);
        }
      });
    }

    setRowData(newRowData);
  }, [contributors]);

  return (
    <Box sx={{ height: "536px", width: "100%", my: 2 }}>
      <DataGrid
        rows={rowData}
        getRowId={(row) => getCreditId(row)}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: PAGE_SIZE } },
        }}
        checkboxSelection={displayCheckbox}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(ids) => {
          onChange(ids);
        }}
      />
    </Box>
  );
};
