import React, { FC, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TableSortLabel,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSeriesVersions } from "../../../../hooks/queries";
import { WhoIsThisForLabels } from "../../../../constants";

export const ProductionNumbers: FC = () => {
  const { seriesCcid, titleCcid } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState<{
    column: string;
    order: "asc" | "desc";
  }>({
    column: "episodeNumber",
    order: "asc",
  });

  const showSeries = !!seriesCcid && seriesCcid !== "0";
  const ccidToPass = showSeries ? seriesCcid : titleCcid;

  const { isLoading, data, error } = useSeriesVersions(
    showSeries,
    ccidToPass,
    rowsPerPage,
    page * rowsPerPage,
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(updatedRowsPerPage);
    setPage(0);
  };

  const handleSort = (column: string) => {
    setSortConfig((prevConfig) => ({
      column,
      order:
        prevConfig.column === column && prevConfig.order === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const convertProductionIdToNumber = (prodId: string) => {
    return Number(prodId.replace(/\D/g, ""));
  };

  const sortedData = data?.versionDetails?.slice().sort((a, b) => {
    const { column, order } = sortConfig;

    let valueA: number =
      column === "episodeNumber"
        ? a.episodeNumber
        : convertProductionIdToNumber(a.productionId);
    let valueB: number =
      column === "episodeNumber"
        ? b.episodeNumber
        : convertProductionIdToNumber(b.productionId);
    valueA = valueA ?? 0;
    valueB = valueB ?? 0;

    return order === "asc" ? valueA - valueB : valueB - valueA;
  });

  if (error)
    return (
      <Container maxWidth="lg" sx={{ textAlign: "center " }}>
        <Typography sx={{ mt: 8 }}>
          Sorry there was an issue loading the production numbers. Please try
          again in a moment.
        </Typography>
      </Container>
    );

  return (
    <Container maxWidth="lg">
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h5" sx={{ mt: 4 }}>
            Production Numbers
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Below are all the production numbers in this series
          </Typography>
          <TableContainer component={Paper} sx={{ mt: 2, mb: 8 }}>
            <Table sx={{ minWidth: 650, p: 4 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {showSeries && (
                    <TableCell
                      onClick={() => handleSort("episodeNumber")}
                      sx={{ cursor: "pointer" }}
                      sortDirection={sortConfig.order}
                    >
                      {
                        <TableSortLabel direction={sortConfig.order}>
                          Episode number
                        </TableSortLabel>
                      }
                    </TableCell>
                  )}
                  <TableCell
                    onClick={() => handleSort("productionId")}
                    sx={{ cursor: "pointer" }}
                    sortDirection={sortConfig.order}
                  >
                    <TableSortLabel direction={sortConfig.order}>
                      Production number
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Third Party ID</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Edit reasons</TableCell>
                  <TableCell>Edit reasons Comments</TableCell>
                  <TableCell>Language</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData?.map((row) => (
                  <TableRow key={row.productionId} style={{ height: 50 }}>
                    {showSeries && <TableCell>{row.episodeNumber}</TableCell>}
                    <TableCell>{row.productionId}</TableCell>
                    <TableCell>{row.externalId}</TableCell>
                    <TableCell>
                      {WhoIsThisForLabels[row.originatingRequestArea] ||
                        row.originatingRequestArea}
                    </TableCell>
                    <TableCell>{row.editReasons}</TableCell>
                    <TableCell>{row.editReasonComments}</TableCell>
                    <TableCell>{row.language}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              sx={{ "& p": { margin: "auto !important" } }}
              component="div"
              count={data?.totalVersions || -1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[10, 20, 40]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}
    </Container>
  );
};
