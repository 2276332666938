import { useState, useEffect } from "react";
import { Typography, Stack, Card, CardContent, Checkbox } from "@mui/material";
import { useFormikContext } from "formik";
import { useUpdateCloseDown } from "../../hooks/mutations/useUpdateCloseDown";
import { CloseDownFormValues } from "./CloseDownFormNew";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

interface PageProps {
  ccid: string;
  type: "series" | "titles";
}

export function SustainabilityPage({ ccid, type }: PageProps) {
  const form = useFormikContext<CloseDownFormValues>();
  const formValues = form.values.sustainability;

  const [finalCarbonAlbertValue, setFinalCarbonAlbertValue] = useState<boolean>(
    formValues.finalCarbonAlbert || false,
  );
  const [offsetAlbertValue, setOffsetAlbertValue] = useState<boolean>(
    formValues.offsetAlbert || false,
  );

  const [greenTeamValue, setGreenTeamValue] = useState<boolean>(
    formValues.greenTeam || false,
  );

  useEffect(() => {
    if (formValues && formValues.finalCarbonAlbert) {
      setFinalCarbonAlbertValue(formValues?.finalCarbonAlbert);
    }
    if (formValues && formValues.offsetAlbert) {
      setOffsetAlbertValue(formValues?.offsetAlbert);
    }
    if (formValues && formValues.greenTeam) {
      setOffsetAlbertValue(formValues?.greenTeam);
    }
  }, [formValues]);

  const { mutate: updateCloseDown } = useUpdateCloseDown(
    () => {
      enqueueSnackbar("Content Autosaved", { variant: "success" });
    },
    () => {
      enqueueSnackbar("There is an error on saving the form data", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  const autosaveForm = (formField: string, input: boolean) => {
    const changes = {
      sustainability: {
        [formField]: input,
      },
    };
    updateCloseDown({ ccid, formData: changes, type });
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Sustainability</Typography>
              <Stack direction="row" alignItems="center">
                <Checkbox
                  id="sustainability.finalCarbonAlbert"
                  checked={finalCarbonAlbertValue}
                  name="finalCarbonAlbert"
                  value={finalCarbonAlbertValue}
                  onChange={(event) => {
                    form.handleChange(event);
                    setFinalCarbonAlbertValue(event.target.checked);
                    autosaveForm("finalCarbonAlbert", event.target.checked);
                  }}
                />
                <Typography>
                  Has all certification evidence been submitted to albert?
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Checkbox
                  id="sustainability.offsetAlbert"
                  checked={offsetAlbertValue}
                  name="offsetAlbert"
                  value={offsetAlbertValue}
                  onChange={(event) => {
                    form.handleChange(event);
                    setFinalCarbonAlbertValue(event.target.checked);
                    autosaveForm("offsetAlbert", event.target.checked);
                  }}
                />
                <Typography>
                  Have the production’s carbon emissions been offset through
                  albert?
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Checkbox
                  id="sustainability.greenTeam"
                  checked={greenTeamValue}
                  name="greenTeam"
                  value={greenTeamValue}
                  onChange={(event) => {
                    form.handleChange(event);
                    setGreenTeamValue(event.target.checked);
                    autosaveForm("greenTeam", event.target.checked);
                  }}
                />
                <Typography>
                  Have any green initiatives been shared with the ITV Green
                  team?
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
