import { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Card,
  CardContent,
  TextField,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useFormikContext } from "formik";
import { useUpdateCloseDown } from "../../hooks/mutations/useUpdateCloseDown";
import { CloseDownFormValues } from "./CloseDownFormNew";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

const tooltipText =
  "Have you removed access to data and deleted data no longer required? This should be in according to policy agreed at the start of production. Contact cybersecurity@itv.com if you have any questions around this or need guidance on how to delete data.";

interface PageProps {
  ccid: string;
  type: "series" | "titles";
}

export function RiskPage({ ccid, type }: PageProps) {
  const form = useFormikContext<CloseDownFormValues>();
  const formValues = form.values.riskLearnings;

  const [cyberSecurityValue, setCyberSecurityValue] = useState<boolean>(
    formValues.cyberSecurity || false,
  );

  useEffect(() => {
    if (formValues && formValues.cyberSecurity) {
      setCyberSecurityValue(formValues?.cyberSecurity);
    }
  }, [formValues]);

  const { mutate: updateCloseDown } = useUpdateCloseDown(
    () => {
      enqueueSnackbar("Content Autosaved", { variant: "success" });
    },
    () => {
      enqueueSnackbar("There is an error on saving the form data", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  const autosaveForm = (formField: string, input: string | boolean) => {
    const changes = {
      riskLearnings: {
        [formField]: input,
      },
    };
    updateCloseDown({ ccid, formData: changes, type });
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Risk Learnings</Typography>
              <Stack>
                <Typography>Health and Safety</Typography>
                <TextField
                  value={formValues.hAndS}
                  id="riskLearnings.hAndS"
                  multiline
                  fullWidth
                  required
                  placeholder="Risks and mitigation. Any accidents?"
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("hAndS", event.target.value);
                  }}
                />
              </Stack>
              <Stack>
                <Typography>Duty of Care</Typography>
                <TextField
                  value={formValues.dutyOfCare}
                  id="riskLearnings.dutyOfCare"
                  multiline
                  fullWidth
                  placeholder="Were there any incidents of note that will change future series procedures? Were there any incidents of note reported on AVA or reported to the Duty of Care team?"
                  required
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("dutyOfCare", event.target.value);
                  }}
                />
              </Stack>
              <Stack direction="row" alignItems="center">
                <Checkbox
                  id="riskLearnings.cyberSecurity"
                  name="cyberSecurity"
                  value={cyberSecurityValue}
                  checked={cyberSecurityValue}
                  onChange={(event) => {
                    form.handleChange(event);
                    setCyberSecurityValue(event.target.checked);
                    autosaveForm("cyberSecurity", event.target.checked);
                  }}
                />
                <Typography>Cyber Security</Typography>
                <Tooltip title={tooltipText}>
                  <IconButton>
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack>
                <Typography>Compliance</Typography>
                <TextField
                  value={formValues.compliance}
                  id="riskLearnings.compliance"
                  multiline
                  fullWidth
                  placeholder="Any Compliance Issues?"
                  required
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("compliance", event.target.value);
                  }}
                />
              </Stack>
              <Stack>
                <Typography>Insurance</Typography>
                <TextField
                  value={formValues.insurance}
                  id="riskLearnings.insurance"
                  multiline
                  fullWidth
                  placeholder="Any incidents of notes, claims, coverage issues related to the format of the show, to consider for future series"
                  required
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("insurance", event.target.value);
                  }}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
