import { useMutation } from "react-query";
import { submitSustainabilityForm } from "../../api/legalDeliverables";
import { SustainabilityFormSubmitData } from "../../types/types";

export function useSubmitSustainabilityForm() {
  return useMutation(
    ({
      ccid,
      formData,
    }: {
      ccid: string;
      formData: SustainabilityFormSubmitData;
    }) => {
      return submitSustainabilityForm({ ccid, formData });
    },
  );
}
