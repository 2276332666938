import { FC, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  CircularProgress,
  Container,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { FormHeader } from "../../components";
import { ProductionPrinciplesFormSteps } from "./ProductionPrinciplesFormSteps";
import { ProductionPrinciplesPage1 } from "./ProductionPrinciplesPage1";
import { ProductionPrinciplesPage2 } from "./ProductionPrinciplesPage2";
import {
  ProductionPrinciplesFormDataFormik,
  ProductionPrinciplesFormDataServer,
} from "../../types/types";
import { transformFormDataToServer } from "./transformFormDataToServer";
import { ProductionPrinciplesFormTitle } from "./ProductionPrinciplesFormTitleStep1";
import {
  useGetProductionPrinciplesData,
  useMetadata,
  useProductionPrinciplesFormSubmission,
} from "../../hooks";
import { FormError } from "../NotificationForm/FormError";
import { useGrantOfcomFormAccess } from "../../hooks/mutations/useGrantOfcomFormAccess";

interface Steps {
  [key: number]: string;
}

interface ProductionPrinciplesPageOneProp {
  provisional: boolean;
}

const stepTitles: Steps = {
  1: "Inclusion Policies",
  2: "Inclusive Programming",
};

const registrationSchema = Yup.object().shape({
  diamondDataConfirmation: Yup.boolean(),
  speakingUpPolicy: Yup.boolean(),
  codeOfConduct: Yup.boolean(),
  antibullyCommitment: Yup.array().when(
    "$antibullyCommitment",
    ([antibullyCommitment], schema) => {
      if (antibullyCommitment) {
        return schema.min(1, "This is a required field");
      }
      return schema;
    },
  ),
  disablityConfirmation: Yup.boolean(),
  diversityCommissioningFund: Yup.string().required("This is a required field"),
  underrepresentedActor: Yup.string().required("This is a required field"),
  underrepresentedGroup: Yup.string().required("This is a required field"),
  underrepresentedStory: Yup.string().required("This is a required field"),
  PoCHairAndMakeup: Yup.string().required("This is a required field"),
  underrepresentedCreative: Yup.string().required("This is a required field"),
  underrepresentedCreativeDetails: Yup.string().when(
    "$underrepresentedCreative",
    ([underrepresentedCreative], schema) => {
      if (underrepresentedCreative === "Yes") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  underrepresentedProductionTeam: Yup.string().required(
    "This is a required field",
  ),
  underrepresentedProductionTeamDetails: Yup.string().when(
    "$underrepresentedProductionTeam",
    ([underrepresentedProductionTeam], schema) => {
      if (underrepresentedProductionTeam === "Yes") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  underrepresentedCrew: Yup.string().required("This is a required field"),
  underrepresentedCrewDetails: Yup.string().when(
    "$underrepresentedCrew",
    ([underrepresentedCrew], schema) => {
      if (underrepresentedCrew === "Yes") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  stepUpSixty: Yup.string().required("This is a required field"),
  stepUpSixtyDetails: Yup.string().when(
    "$stepUpSixty",
    ([stepUpSixty], schema) => {
      if (stepUpSixty === "Yes") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  paidTrainee: Yup.string().required("This is a required field"),
});

export const ProductionPrinciplesForm: FC<ProductionPrinciplesPageOneProp> = ({
  provisional,
}) => {
  const { seriesCcid, programmeCcid, titleCcid } = useParams<string>();
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [
    fetchedProdctionPrinciplesFormStatus,
    setFetchedProductionPrinciplesFormStatus,
  ] = useState<boolean>(false);
  const ccid = titleCcid || seriesCcid;
  const isSeries = Boolean(seriesCcid);
  const metadata = useMetadata(ccid);
  const goToPreviousPage = () =>
    navigate(
      isSeries
        ? `/programmes/${programmeCcid}/series/${seriesCcid}`
        : `/special/${titleCcid}`,
    );

  const {
    data: fetchedProductionPrinciplesformData,
    isLoading: loadingProductionPrinciplesData,
    error: prodPrinciplesError,
  } = useGetProductionPrinciplesData(ccid as string, provisional);
  const { mutate: submitProductionPrinciplesForm } =
    useProductionPrinciplesFormSubmission(goToPreviousPage);
  const isFormProvisional = provisional;
  const { mutate: requestAccess } = useGrantOfcomFormAccess();

  const transformProductionPrinciplesData = (
    incomingProductionPrinciplesdata: ProductionPrinciplesFormDataServer,
  ) => {
    const booleanToYesNo = (val: boolean | null) => {
      if (val === true) return "Yes";
      if (val === false) return "No";
      return "";
    };

    const now = new Date();

    const inclusionPolicyObject =
      incomingProductionPrinciplesdata.inclusionPolicies || {
        diamondDataConfirmation: null,
        speakingUpPolicy: null,
        codeOfConduct: null,
        antibullyCommitment: null,
        disabilityConfirmation: null,
        diversityCommissioningFund: null,
      };

    const inclusionProgrammingObject =
      incomingProductionPrinciplesdata.inclusiveProgramming || {
        underrepresentedActor: null,
        underrepresentedGroup: null,
        underrepresentedStory: null,
        PoCHairAndMakeup: null,
        underrepresentedCreative: null,
        underrepresentedCreativeDetails: null,
        underrepresentedProductionTeam: null,
        underrepresentedProductionTeamDetails: null,
        underrepresentedCrew: null,
        underrepresentedCrewDetails: null,
        stepUpSixty: null,
        stepUpSixtyDetails: null,
        paidTrainee: null,
      };

    const transformedData: ProductionPrinciplesFormDataFormik = {
      diamondDataConfirmation:
        inclusionPolicyObject.diamondDataConfirmation || false,
      speakingUpPolicy: inclusionPolicyObject.speakingUpPolicy || false,
      codeOfConduct: inclusionPolicyObject.codeOfConduct || false,
      antibullyCommitment: inclusionPolicyObject.antibullyCommitment || [],
      disabilityConfirmation:
        inclusionPolicyObject.disabilityConfirmation || false,
      diversityCommissioningFund:
        inclusionPolicyObject.diversityCommissioningFund || "",
      modifiedTime:
        incomingProductionPrinciplesdata?.modifiedTime ||
        now.toISOString().slice(0, 19),
      submitted: incomingProductionPrinciplesdata.submitted || false,
      submittedBy: incomingProductionPrinciplesdata.submittedBy || "",
      underrepresentedActor: booleanToYesNo(
        inclusionProgrammingObject.underrepresentedActor,
      ),
      underrepresentedGroup: booleanToYesNo(
        inclusionProgrammingObject.underrepresentedGroup,
      ),
      underrepresentedStory: booleanToYesNo(
        inclusionProgrammingObject.underrepresentedStory,
      ),
      PoCHairAndMakeup: booleanToYesNo(
        inclusionProgrammingObject.PoCHairAndMakeup,
      ),
      underrepresentedCreative: booleanToYesNo(
        inclusionProgrammingObject.underrepresentedCreative,
      ),
      underrepresentedCreativeDetails:
        inclusionProgrammingObject.underrepresentedCreativeDetails || "",
      underrepresentedProductionTeam: booleanToYesNo(
        inclusionProgrammingObject.underrepresentedProductionTeam,
      ),
      underrepresentedProductionTeamDetails:
        inclusionProgrammingObject.underrepresentedProductionTeamDetails || "",
      underrepresentedCrew: booleanToYesNo(
        inclusionProgrammingObject.underrepresentedCrew,
      ),
      underrepresentedCrewDetails:
        inclusionProgrammingObject.underrepresentedCrewDetails || "",
      stepUpSixty: booleanToYesNo(inclusionProgrammingObject.stepUpSixty),
      stepUpSixtyDetails: inclusionProgrammingObject.stepUpSixtyDetails || "",
      paidTrainee: booleanToYesNo(inclusionProgrammingObject.paidTrainee),
    };

    return transformedData;
  };

  useEffect(() => {
    if (
      fetchedProductionPrinciplesformData &&
      fetchedProductionPrinciplesformData.data.submitted
    ) {
      setFetchedProductionPrinciplesFormStatus(
        fetchedProductionPrinciplesformData.data.submitted,
      );
    }
  }, [fetchedProductionPrinciplesformData]);

  const navigate = useNavigate();
  const onSubmit = (values: ProductionPrinciplesFormDataFormik) => {
    const useCcid: string = ccid as string;
    submitProductionPrinciplesForm({
      ccid: useCcid,
      formData: transformFormDataToServer(values),
      autosave: false,
      provisional,
    });
  };

  const Loading = (
    <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
      <CircularProgress />
    </Box>
  );

  const Content = (
    <>
      <FormHeader
        formTitle="Production Principles Form"
        showFormStatus
        showFormSubmission
        isFormProvisional={provisional}
        isFormSubmitted={fetchedProdctionPrinciplesFormStatus}
      />
      <Container fixed disableGutters>
        <Formik
          initialValues={transformProductionPrinciplesData(
            fetchedProductionPrinciplesformData
              ? fetchedProductionPrinciplesformData.data
              : {},
          )}
          validationSchema={registrationSchema}
          onSubmit={onSubmit}
        >
          {({ submitForm, isSubmitting, validateForm, setTouched }) =>
            isSubmitting ? (
              <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Form>
                <Stack alignItems="left" spacing={5} direction="row">
                  <Box sx={{ maxWidth: "100%", p: 1 }}>
                    <ProductionPrinciplesFormSteps
                      onStepChange={setStepNumber}
                      onSubmitForm={submitForm}
                      onFormValidate={validateForm}
                      setTouched={setTouched}
                      submittedBy={
                        fetchedProductionPrinciplesformData
                          ? fetchedProductionPrinciplesformData.data.submittedBy
                          : undefined
                      }
                      modifiedTime={
                        fetchedProductionPrinciplesformData
                          ? fetchedProductionPrinciplesformData.data
                              .modifiedTime
                          : undefined
                      }
                    />
                  </Box>
                  <Box>
                    <Stack>
                      <Typography variant="h4">
                        {`Section ${stepNumber} of 2 : ${stepTitles[stepNumber]}`}
                      </Typography>
                      <ProductionPrinciplesFormTitle
                        genre={metadata.data?.genre}
                      />
                    </Stack>
                    {stepNumber === 1 ? (
                      <ProductionPrinciplesPage1
                        ccid={ccid as string}
                        provisional={isFormProvisional}
                      />
                    ) : (
                      <ProductionPrinciplesPage2
                        ccid={ccid as string}
                        provisional={isFormProvisional}
                      />
                    )}
                  </Box>
                </Stack>
              </Form>
            )
          }
        </Formik>
      </Container>
    </>
  );

  return (
    <>
      {loadingProductionPrinciplesData && Loading}
      {!loadingProductionPrinciplesData &&
        prodPrinciplesError &&
        prodPrinciplesError.response?.status !== 404 && (
          // Deliberately using Ofcom form access here as BE uses Ofcom to determine both forms
          <FormError
            formError={prodPrinciplesError}
            requestAccess={requestAccess}
          />
        )}
      {!loadingProductionPrinciplesData &&
        (!prodPrinciplesError ||
          prodPrinciplesError.response?.status === 404) &&
        Content}
    </>
  );
};
