import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import { Warning, WarningAmber } from "@mui/icons-material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const iconSize = {
  width: "1rem",
  height: "1rem",
  position: "relative",
  margin: 1,
};

const RiskIcon = ({
  rating,
  completed,
  showError,
  highlighted,
}: {
  rating: string | undefined;
  completed: boolean;
  showError: boolean;
  highlighted?: boolean;
}): JSX.Element => {
  if (rating === "Insignificant") {
    return <ChangeHistoryIcon sx={iconSize} />;
  }
  if (rating === "Low") {
    return <WarningAmber color="disabled" sx={iconSize} />;
  }
  if (rating === "Medium") {
    return completed ? (
      <Warning color="warning" sx={iconSize} />
    ) : (
      <WarningAmber color="warning" sx={iconSize} />
    );
  }

  if (rating === "High") {
    return completed ? (
      <Warning color="error" sx={iconSize} />
    ) : (
      <WarningAmber color="error" sx={iconSize} />
    );
  }
  if (completed)
    return highlighted ? (
      <CircleIcon sx={{ ...iconSize, color: "green" }} />
    ) : (
      <CheckCircleIcon sx={{ ...iconSize, color: "green" }} />
    );

  return highlighted ? (
    <CircleIcon color={showError ? "error" : "primary"} sx={iconSize} />
  ) : (
    <CircleOutlinedIcon color={showError ? "error" : "primary"} sx={iconSize} />
  );
};

export default RiskIcon;
