import { FC, useCallback, useEffect } from "react";
import { Stack, Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { ContributorProps } from "../../../../types/types";
import { noop, clone } from "../../../../utils/appHelper";
import { SelectableContributors } from "../../../SelectableContributors";
import { ContributorsTable } from "../../../../containers/Synopsis/Edit/Contributors/TitleContributors/ContributorsTable";
import { useContributors, useUpdateContributors } from "../../../../hooks";
import { cleanContributors } from "../../../../containers/Synopsis/Edit/Contributors/contributor.helper";
import {
  NotificationService,
  FINISH_CONTRIBUTORS_FORM_STARTUP,
} from "../../../../services/notificationService";
import { SnackbarDismiss } from "../../../../componentsV2/SnackBarDismiss";

export interface ContributorsProps {
  contributors: ContributorProps[];
  onChange(value: ContributorProps[]): void;
  onAddNewContributor(value: ContributorProps[]): void;
  disabled: boolean;
}

export const StartUpFormContributors: FC<ContributorsProps> = ({
  onAddNewContributor,
  contributors = [],
  onChange = noop,
  disabled = false,
}) => {
  const { seriesCcid, titleCcid } = useParams<string>();
  const queryClient = useQueryClient();
  const levelCcid = titleCcid || seriesCcid;
  const internalContributors = contributors;

  const { data: contributorData } = useContributors(
    levelCcid,
    Boolean(seriesCcid),
  );

  const seriesContributors = contributorData?.contributors || [];

  const combinedContributors = seriesContributors.concat(internalContributors);

  const { mutate } = useUpdateContributors(
    levelCcid,
    cleanContributors(seriesContributors as ContributorProps[]),
    cleanContributors(combinedContributors),
    () => {
      enqueueSnackbar("New contributors have been saved!", {
        variant: "success",
      });
      queryClient.invalidateQueries("getContributors");
    },
    () => {
      enqueueSnackbar("Error on saving contributors", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
    seriesCcid ? "series" : "titles",
  );

  const handleOnAdd = () => {
    const emptyContributor: ContributorProps = {
      forenames: "",
      surname: "",
      character: "",
      role: "",
    };
    onAddNewContributor([...internalContributors, emptyContributor]);
  };

  const handleSubmit = useCallback(() => {
    mutate({});
  }, [mutate]);

  useEffect(() => {
    NotificationService.sub(FINISH_CONTRIBUTORS_FORM_STARTUP, handleSubmit);

    return () => {
      NotificationService.remove(
        FINISH_CONTRIBUTORS_FORM_STARTUP,
        handleSubmit,
      );
    };
  }, [handleSubmit]);

  return (
    <Stack spacing={2}>
      <div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOnAdd}
        >
          Add a new key contributor
        </Button>
      </div>

      <SelectableContributors
        contributors={internalContributors}
        disabled={disabled}
        selectable={false}
        onChange={(newContributor: ContributorProps, index: number) => {
          if (newContributor.forenames && newContributor.role) {
            let newValue = [...internalContributors];
            newValue[index] = newContributor;
            newValue = newValue.filter(
              (contributor: ContributorProps) =>
                contributor.forenames && contributor.role,
            );
            onChange(newValue);
          }
        }}
        onDelete={(contributorToDelete: ContributorProps) => {
          const newValue = internalContributors
            .filter(
              (contributor: ContributorProps) =>
                contributor !== contributorToDelete,
            )
            .filter(
              (contributor: ContributorProps) =>
                contributor.forenames && contributor.role,
            );

          onChange(newValue);
        }}
      />
      <Typography variant="h5" marginBottom={2}>
        Contributors present elsewhere in this series
      </Typography>

      <ContributorsTable
        displayCheckbox={false}
        contributors={seriesContributors}
        onChange={(ids) => {
          const newContributors = clone(seriesContributors);
          const newContributorsArr: ContributorProps[] = [];
          newContributors.forEach((contributor: ContributorProps) => {
            const newContributor = contributor;
            newContributor.selected = ids.includes(
              newContributor.contributorId,
            );
            newContributorsArr.push(newContributor);
          });
        }}
      />
    </Stack>
  );
};
