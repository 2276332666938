import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useBrandMetadata, useTitleMetadata } from "../hooks/queries";
import { Menu } from "../containers/Synopsis/Edit/Menu";
import { setRecentlyViewedShows } from "../containers/Synopsis/utils/recentlyViewedShows";
import { setRecentlyActiveProductions } from "../containers/Synopsis/utils/recentlyActiveProductions";
import { UserAccessAndOnboard } from "../containers/Synopsis/Edit/UserAccessAndOnboard";
import NavMenu from "../componentsV2/NavMenu/NavMenu";

const BrandedSpecialPage: React.FC = () => {
  const { programmeCcid, seriesCcid, titleCcid } = useParams();
  const { data: brandMetaData } = useBrandMetadata(programmeCcid, true);
  const { data: titleMetaData } = useTitleMetadata(titleCcid);
  const lastPath = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1,
  );
  const baseUrl = `programmes/brandedSpecials/${programmeCcid}/special/${titleCcid}`;
  const currentTab =
    lastPath !== programmeCcid &&
    lastPath !== seriesCcid &&
    lastPath !== titleCcid &&
    lastPath !== ""
      ? lastPath
      : "tasks";

  useEffect(() => {
    if (brandMetaData?.brandName) {
      setRecentlyViewedShows(
        brandMetaData.brandCcid,
        brandMetaData.brandName,
        false,
      );
    } else if (titleMetaData?.titleName && programmeCcid === undefined) {
      setRecentlyViewedShows(
        titleMetaData.titleCcid,
        titleMetaData.titleName,
        true,
      );
    }
  }, [
    brandMetaData?.brandCcid,
    brandMetaData?.brandName,
    titleMetaData?.titleCcid,
    titleMetaData?.titleName,
    programmeCcid,
  ]);

  useEffect(() => {
    setRecentlyActiveProductions(programmeCcid, seriesCcid, titleCcid);
  }, [programmeCcid, seriesCcid, titleCcid]);

  return (
    <>
      <UserAccessAndOnboard />
      <NavMenu
        programmeCcid={programmeCcid}
        seriesCcid={seriesCcid}
        titleCcid={titleCcid}
        brandedSpecial
      />
      <Menu
        tab={currentTab}
        basePath={baseUrl}
        programmeCcid={programmeCcid}
        seriesCcid={seriesCcid}
        titleCcid={titleCcid}
      />
      <Outlet />
    </>
  );
};

export default BrandedSpecialPage;
