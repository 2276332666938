import { Typography, Stack, Card, CardContent, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { CloseDownFormValues } from "./CloseDownFormNew";
import { useUpdateCloseDown } from "../../hooks/mutations/useUpdateCloseDown";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

interface PageProps {
  ccid: string;
  type: "series" | "titles";
}

export function BudgetPage({ ccid, type }: PageProps) {
  const form = useFormikContext<CloseDownFormValues>();
  const formValues = form.values.budget;

  const { mutate: updateCloseDown } = useUpdateCloseDown(
    () => {
      enqueueSnackbar("Content Autosaved", { variant: "success" });
    },
    () => {
      enqueueSnackbar("There is an error on saving the form data", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  const autosaveForm = (formField: string, input: string) => {
    const changes = {
      budget: {
        [formField]: input,
      },
    };
    updateCloseDown({ ccid, formData: changes, type });
  };
  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Budget & Schedule Learnings</Typography>
              <Stack>
                <Typography>Design (if applicable)</Typography>
                <TextField
                  value={formValues.design || undefined}
                  id="budget.design"
                  multiline
                  fullWidth
                  required
                  placeholder="Rig time, Designer/fee, Cost of set/build"
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("design", event.target.value);
                  }}
                />
              </Stack>
              <Stack>
                <Typography>
                  Final Cost Report Variance and Explanation
                </Typography>
                <TextField
                  value={formValues.finalCost}
                  id="budget.finalCost"
                  multiline
                  fullWidth
                  required
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("finalCost", event.target.value);
                  }}
                />
              </Stack>
              <Stack>
                <Typography>Procurement/deals</Typography>
                <TextField
                  value={formValues.finalCost}
                  id="budget.procurement"
                  multiline
                  fullWidth
                  placeholder="Resource Provider\Savings Made"
                  required
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("finalCost", event.target.value);
                  }}
                />
              </Stack>
              <Stack>
                <Typography>Key Resources</Typography>
                <TextField
                  value={formValues.keyResources}
                  id="budget.keyResources"
                  multiline
                  fullWidth
                  placeholder="Costs v Quotes, Quality, Any internal resources used?"
                  required
                  maxRows={8}
                  minRows={8}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("keyResources", event.target.value);
                  }}
                />
              </Stack>
              <Stack>
                <Typography>On Screen Talent</Typography>
                <TextField
                  value={formValues.onScreenTalent}
                  id="budget.onScreenTalent"
                  multiline
                  fullWidth
                  placeholder="Where there any unusual Contracts, Support costs, Rights"
                  required
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("onScreenTalent", event.target.value);
                  }}
                />
              </Stack>
              <Stack>
                <Typography>Rights</Typography>
                <TextField
                  value={formValues.rights}
                  id="budget.rights"
                  multiline
                  fullWidth
                  placeholder="Where there any unusal rights required & acquired - Are the intended position for the rights and clearances agreed during production true to what was actually delivered? "
                  required
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("rights", event.target.value);
                  }}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
