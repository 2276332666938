import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Stack,
  Box,
  CircularProgress,
  Button,
  Dialog,
  TextField,
  InputAdornment,
} from "@mui/material";
import { IconikUser, SearchedUser } from "../../../../../types/types";
import { useParams } from "react-router-dom";

import { useGetIconikUsers } from "../../../../../hooks/queries/useGetIconikUsers";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridRowId,
} from "@mui/x-data-grid";
import { Add, Remove, Search } from "@mui/icons-material";
import { AddIconikUser } from "./AddIconikUser";
import {
  useAddUserToIconikGroup,
  useRemoveUsersFromIconik,
} from "../../../../../hooks/mutations/";
import { createRushesURL } from "../../../../../api/iconik";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";
import { useQueryClient } from "react-query";

const PAGE_SIZE = 15;

const ManagedBy = {
  ICONIK: "Iconik via pts@itv.com",
  STUDIOS_PORTAL: "Studios Portal",
};

const IconikUserManagement: React.FC = () => {
  const { seriesCcid, titleCcid } = useParams();
  const levelCcid = titleCcid || seriesCcid;
  const queryClient = useQueryClient();

  const {
    data: GetUsersData,
    isLoading: gettingUsers,
    refetch: refetchIconikUsers,
  } = useGetIconikUsers(levelCcid as string);

  const { mutate: addUsers, isLoading: addingUsers } = useAddUserToIconikGroup(
    () => refetchIconikUsers(),
    () => {
      enqueueSnackbar("There was an error adding the iconik user", {
        variant: "error",
        action: SnackbarDismiss,
      });
    },
  );

  const { mutate: removeUsers, isLoading: removingUsers } =
    useRemoveUsersFromIconik(
      () => {
        setSelectedRowIds([]);
        enqueueSnackbar("User(s) Removed");
        setSelectedRowIds([]);
        queryClient.invalidateQueries("getIconikUsers");
      },
      () => {
        enqueueSnackbar("There was an error removing the iconik user(s)", {
          variant: "error",
          action: SnackbarDismiss,
        });
      },
    );
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingRushes, setLoadingRushes] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [filterEmail, setFilterEmail] = useState<string>("");
  const [users, setUsers] = useState<IconikUser[]>([]);

  useEffect(() => {
    if (GetUsersData) {
      const studiosUsers = GetUsersData.studiosPortalUsers.map((user) => ({
        ...user,
        managedBy: ManagedBy.STUDIOS_PORTAL,
      }));
      const otherUsers = GetUsersData.otherUsers.map((user) => ({
        ...user,
        managedBy: ManagedBy.ICONIK,
      }));
      setUsers(studiosUsers.concat(otherUsers));
    }
  }, [GetUsersData, removeUsers, addUsers]);

  const filteredUsers = users.filter((user) =>
    user.email.toLowerCase().includes(filterEmail.toLowerCase()),
  );

  const handleAddNewUser = () => {
    setModalOpen(true);
  };

  const handleRemoveUser = () => {
    const usersToRemove = users.reduce<string[]>((acc, rowInstance) => {
      const matchedId = selectedRowIds.includes(rowInstance.id);
      if (matchedId) acc.push(rowInstance.email);
      return acc;
    }, []);
    removeUsers({
      ccid: levelCcid,
      groupId: GetUsersData?.groupId,
      userEmails: usersToRemove,
    });
  };

  const handleNavigateToIconik = () => {
    enqueueSnackbar("Checking iconik - a new tab will open shortly", {
      variant: "info",
    });
    if (seriesCcid) {
      setLoadingRushes(true);
      createRushesURL("series", seriesCcid)
        .then((data) => {
          setLoadingRushes(false);
          window.open(data.url, "_blank");
        })
        .catch(() => {
          setLoadingRushes(false);
          enqueueSnackbar("There was an error opening Iconik", {
            variant: "error",
            action: SnackbarDismiss,
          });
        });
    }
  };

  const columns: GridColDef[] = [
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "lastName", headerName: "Last Name", width: 200 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "managedBy",
      headerName: "Managed By",
      width: 250,
    },
  ];

  return (
    <Container fixed>
      <Stack direction="column" spacing={3}>
        <Box sx={{ py: 3 }}>
          <Typography variant="h5">Iconik User Management</Typography>
          <Typography>
            View and manage Iconik users, and add new ones.
          </Typography>
        </Box>

        <TextField
          id="outlined-basic"
          label="Filter users"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="standard"
          onChange={(e) => setFilterEmail(e.target.value)}
        />

        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack spacing={1} direction="row">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={handleAddNewUser}
            >
              Add a new user to Iconik
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedRowIds.length < 1}
              startIcon={<Remove />}
              onClick={handleRemoveUser}
            >
              Remove Selected User(s)
            </Button>
          </Stack>
          <Button
            variant="contained"
            color="primary"
            sx={{ height: "50%" }}
            onClick={handleNavigateToIconik}
          >
            Access Iconik
          </Button>
        </Stack>

        <Box
          sx={{
            height: "calc(100vh - 370px)",
            width: "100%",
            background: "white",
            position: "relative",
          }}
        >
          {gettingUsers || addingUsers || loadingRushes || removingUsers ? (
            <Stack alignItems="center" m={4}>
              <CircularProgress />
            </Stack>
          ) : (
            <DataGrid
              rows={filteredUsers}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: PAGE_SIZE } },
              }}
              onRowSelectionModelChange={(ids) => {
                setSelectedRowIds(ids);
              }}
              checkboxSelection
              disableRowSelectionOnClick
              disableColumnMenu
              isRowSelectable={(params: GridRowParams) =>
                params.row.managedBy === ManagedBy.STUDIOS_PORTAL
              }
              getRowClassName={(params: GridRowParams) =>
                params.row.managedBy === ManagedBy.STUDIOS_PORTAL
                  ? ""
                  : "non-studios-user"
              }
              sx={{
                "& .non-studios-user": {
                  backgroundColor: "#f0f0f0",
                  color: "#999",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "& .MuiDataGrid-columnHeader": {
                  // Forced to use important since overriding inline styles
                  height: "unset !important",
                },
                "& .MuiDataGrid-columnHeaders": {
                  // Forced to use important since overriding inline styles
                  maxHeight: "168px !important",
                },
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                  {
                    marginTop: "1em",
                    marginBottom: "1em",
                  },
              }}
            />
          )}
        </Box>
      </Stack>
      <Dialog fullWidth open={modalOpen} aria-labelledby="add-iconik-user">
        <AddIconikUser
          onAdd={(selectedUser: SearchedUser) => {
            if (users.find((user) => user.email === selectedUser.email)) {
              enqueueSnackbar("User already exists in Iconik", {
                variant: "error",
                action: SnackbarDismiss,
              });
            } else {
              const payload = {
                ccid: levelCcid as string,
                addUserToIconikRequest: {
                  user: {
                    email: selectedUser.email,
                    firstName: selectedUser.firstName,
                    lastName: selectedUser.lastName,
                    id: selectedUser.id,
                  },
                },
              };
              addUsers(payload);
              setModalOpen(false);
            }
          }}
          onCancel={() => {
            setModalOpen(false);
          }}
        />
      </Dialog>
    </Container>
  );
};

export default IconikUserManagement;
