import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  TextField,
  Stack,
  Typography,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import Timecode from "smpte-timecode";

import { FRAME_RATE, isValidTimecode } from "../util";

const placeholder = "HH:MM:SS:FF";
const helperText = `format must be ${placeholder}`;

export interface TimeRange {
  startTime: string | undefined;
  endTime: string | undefined;
}

interface TimeCodeEditorProps {
  titleCcid: string;
  selectedVersionId: string;
  timeRange: TimeRange | undefined;
  hasFixedTimecodeLength: boolean;
  onTimeRangeChange(timeRange: TimeRange): void;
  onError(msg: string): void;
}

/**
 * The number of frames that get added on to each fixed timecode length
 */
const FIXED_FRAME_OFFSET = 14;

const fixedTimecodeLengths = [
  { value: 15, label: "15 seconds and 14 frames" },
  { value: 16, label: "16 seconds and 14 frames" },
  { value: 17, label: "17 seconds and 14 frames" },
];

/**
 * Determines whether, given a base number of seconds, the option matches the current time range after applying
 * the fixed frame offset to it
 */
function isMatchingTimecode({
  timeRange,
  seconds,
}: {
  timeRange: TimeRange | undefined;
  seconds: number;
}): boolean {
  if (!timeRange || !timeRange.startTime || !timeRange.endTime) {
    return false;
  }

  if (
    !isValidTimecode(timeRange.startTime) ||
    !isValidTimecode(timeRange.endTime)
  ) {
    return false;
  }

  const offset = Timecode(
    {
      hours: 0,
      minutes: 0,
      seconds,
      frames: FIXED_FRAME_OFFSET,
    },
    FRAME_RATE,
  );

  const start = Timecode(timeRange.startTime, FRAME_RATE);
  const end = Timecode(timeRange.endTime, FRAME_RATE);

  return end.subtract(offset).toString() === start.toString();
}

export const TimeCodeEditor: FC<TimeCodeEditorProps> = ({
  titleCcid,
  selectedVersionId,
  timeRange,
  hasFixedTimecodeLength,
  onTimeRangeChange,
  onError,
}) => {
  const disabled = !titleCcid || !selectedVersionId;
  const [hasStartTimeError, setHasStartTimeError] = useState(false);
  const [hasEndTimeError, setHasEndTimeError] = useState(false);
  const [fixedTimecodeValue, setFixedTimecodeValue] = useState(
    hasFixedTimecodeLength
      ? fixedTimecodeLengths.find(({ value }) => {
          return isMatchingTimecode({ seconds: value, timeRange });
        })?.value || undefined
      : undefined,
  );

  const handleChange = useCallback(
    (fieldName: "startTime" | "endTime") =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const validTimecode = isValidTimecode(e.target.value);

        const newTimeRange: TimeRange =
          validTimecode && fieldName === "startTime" && fixedTimecodeValue
            ? {
                startTime: e.target.value,
                endTime: Timecode(e.target.value, FRAME_RATE)
                  .add(
                    Timecode(
                      {
                        hours: 0,
                        minutes: 0,
                        seconds: fixedTimecodeValue,
                        frames: FIXED_FRAME_OFFSET,
                      },
                      FRAME_RATE,
                    ),
                  )
                  .toString(),
              }
            : {
                startTime: undefined,
                endTime: undefined,
                ...timeRange,
                [fieldName]: e.target.value,
              };

        if (fieldName === "startTime") {
          setHasStartTimeError(!validTimecode);
        } else {
          setHasEndTimeError(!validTimecode);
        }

        if (!validTimecode) {
          onError(helperText);
        } else {
          onError("");
        }

        onTimeRangeChange(newTimeRange);
      },
    [onError, onTimeRangeChange, timeRange, fixedTimecodeValue],
  );

  const handleDropdownChange = (e: SelectChangeEvent<number>) => {
    if (!timeRange) {
      return;
    }

    const secondsToAdd = Number(e.target.value);

    const newTimestamp = Timecode(timeRange.startTime, FRAME_RATE).add(
      Timecode(
        {
          hours: 0,
          minutes: 0,
          seconds: secondsToAdd,
          frames: FIXED_FRAME_OFFSET,
        },
        FRAME_RATE,
      ),
    );

    const newTimeRange: TimeRange = {
      ...timeRange,
      endTime: newTimestamp.toString(),
    };

    if (!hasStartTimeError && !hasEndTimeError) {
      setFixedTimecodeValue(secondsToAdd);
    }

    onTimeRangeChange(newTimeRange);
  };

  useEffect(() => {
    if (!timeRange) {
      return;
    }

    // when time range change, it should check if it has error or not again
    if (isValidTimecode(timeRange?.startTime) && hasStartTimeError) {
      setHasStartTimeError(false);
    }

    if (isValidTimecode(timeRange?.endTime) && hasEndTimeError) {
      setHasEndTimeError(false);
    }
  }, [hasEndTimeError, hasStartTimeError, timeRange]);

  return (
    <Stack>
      <Stack
        direction={hasFixedTimecodeLength ? "column" : "row"}
        spacing={3}
        data-testid="time-code-editor"
        sx={{ padding: "0 0.5rem" }}
      >
        <TextField
          fullWidth
          size="small"
          label="Start of media"
          id="startTime"
          data-testid="startTime"
          disabled={disabled}
          error={hasStartTimeError}
          onChange={handleChange("startTime")}
          placeholder={placeholder}
          value={timeRange?.startTime || ""}
        />

        {!hasFixedTimecodeLength && <Typography variant="h5">-</Typography> && (
          <TextField
            fullWidth
            size="small"
            label="End of media"
            id="endTime"
            data-testid="endTime"
            disabled={disabled}
            error={hasEndTimeError}
            onChange={handleChange("endTime")}
            placeholder={placeholder}
            value={timeRange?.endTime || ""}
          />
        )}
        {hasFixedTimecodeLength && (
          <FormControl disabled={disabled} fullWidth size="small">
            <InputLabel id="end-time-label">Length of promo clip</InputLabel>
            <Select
              value={fixedTimecodeValue || ""}
              onChange={handleDropdownChange}
              disabled={disabled || !timeRange?.startTime || hasStartTimeError}
              label="Length of promo clip"
            >
              {fixedTimecodeLengths.map((timecode) => (
                <MenuItem key={timecode.value} value={timecode.value}>
                  {timecode.label}
                </MenuItem>
              ))}
            </Select>

            {timeRange && timeRange.endTime ? (
              <FormHelperText sx={{ marginLeft: "4px" }}>
                End of media: {timeRange.endTime}
              </FormHelperText>
            ) : null}
          </FormControl>
        )}
      </Stack>
    </Stack>
  );
};
