/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import {
  Divider,
  Button,
  Stack,
  Box,
  Container,
  LinearProgress,
  Typography,
  Paper,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { useContributors, useUpdateContributors } from "../../../../../hooks";
import {
  ContributorProps,
  ContributorsResponsePayload,
} from "../../../../../types/types";
import { SelectableContributors } from "../../../../../components/SelectableContributors/SelectableContributors";
import { ActionBar } from "../../../../../components";
import {
  NotificationService,
  FINISH_CONTRIBUTORS_FORM,
  CLOSE,
  CONTRIBUTORS_CHANGE,
} from "../../../../../services/notificationService";
import { clone } from "../../../../../utils/appHelper";
import { cleanContributors, getCreditId } from "../contributor.helper";
import { ExcludeRolesFromBilling } from "../ExcludeRolesFromBilling";
import { ContributorsTable } from "./ContributorsTable";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";

interface TitleContributorsProps {
  inModal?: boolean;
  contributorsData: ContributorsResponsePayload;
  isContributorsDataLoading: boolean;
  hasContributorsChanged?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setElsewhereContributors: (contributors: any) => void;
}

export const NoContributors: FC<{ onAdd(): void; loading: boolean }> = ({
  onAdd,
  loading = false,
}) => (
  <Stack flexDirection="column" textAlign="center" spacing={1}>
    <Box maxWidth={400} alignSelf="center">
      You do not have any contributors included for this episode. Select from
      the contributors list below or add a new one!
    </Box>
    <Box>
      <LoadingButton
        variant="contained"
        color="primary"
        startIcon={<Add />}
        loading={loading}
        onClick={onAdd}
      >
        {" "}
        Add new contributor
      </LoadingButton>
    </Box>
  </Stack>
);

export const TitleContributors: FC<TitleContributorsProps> = ({
  inModal,
  contributorsData,
  isContributorsDataLoading,
  hasContributorsChanged = false,
  setElsewhereContributors,
}) => {
  const queryClient = useQueryClient();
  const { titleCcid, seriesCcid } = useParams<string>();
  const {
    data: seriesContributorData,
    isLoading: loadingSeriesContributorData,
  } = useContributors(seriesCcid, true);

  const initialContributors = useRef<ContributorProps[]>([]);

  const [contributorsChanged, setContributorsChanged] = useState<boolean>(
    hasContributorsChanged,
  );
  const [contributors, setContributors] = useState<ContributorProps[]>([]);
  const [seriesContributors, setSeriesContributors] = useState<
    ContributorProps[]
  >([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { mutate, isLoading: updatingContributors } = useUpdateContributors(
    titleCcid,
    cleanContributors(initialContributors.current as ContributorProps[]),
    cleanContributors(contributors),
    () => {
      enqueueSnackbar("New contributors have been saved!", {
        variant: "success",
      });
      setContributorsChanged(false);
      initialContributors.current = contributors;
      queryClient.invalidateQueries("getContributors");
    },
    () => {
      setContributorsChanged(false);
      enqueueSnackbar("Error on saving contributors", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  const hasSelectedContributors = (items: ContributorProps[]) =>
    items.find((contributor) => contributor.selected);

  const handleContributorsChange = useCallback(
    (newContributors: ContributorProps[]) => {
      setContributors(newContributors);
      NotificationService.pub(CONTRIBUTORS_CHANGE, {
        saveBarIsVisible: true,
        contributors: clone(newContributors),
      });
    },
    [],
  );

  const setActionBarVisibility = useCallback(
    (newContributor: ContributorProps) => {
      const visible = Boolean(newContributor.forenames && newContributor.role);
      setContributorsChanged(visible);
    },
    [],
  );

  const updateContributor = useCallback(
    (newContributor: ContributorProps, index: number) => {
      contributors[index] = { ...newContributor };
      handleContributorsChange([...contributors]);
    },
    [contributors, handleContributorsChange],
  );

  const handleOnAdd = () => {
    const emptyContributor: ContributorProps = {
      forenames: "",
      surname: "",
      character: "",
      // role: { value: "ACTOR", displayName: "Actor" },
      selected: false,
    };

    setContributors([...contributors, emptyContributor]);
    // setContributorsChanged(true);
  };

  useEffect(() => {
    if (contributorsData) {
      setContributors(clone(contributorsData.contributors));
      initialContributors.current = clone(contributorsData.contributors);
    }
  }, [contributorsData]);

  useEffect(() => {
    if (seriesContributorData) {
      setSeriesContributors(seriesContributorData.contributors);
    }
  }, [seriesContributorData]);

  return (
    <Container sx={{ marginTop: 2 }}>
      <Typography variant="h5" style={{ marginBottom: 4 }}>
        Here you can indicate which contributors were a part of this episode
      </Typography>
      <Paper
        sx={{ marginBottom: 2, borderRadius: 2, padding: 2, boxShadow: 6 }}
      >
        <Stack justifyContent="space-between" direction="row">
          <Typography variant="h5">Contributors in this episode</Typography>
          <Box marginTop={1}>
            <ExcludeRolesFromBilling seriesCcid={seriesCcid as string} />
          </Box>
        </Stack>

        <div>
          <Button
            variant="outlined"
            color="secondary"
            disabled={!hasSelectedContributors(contributors)}
            onClick={() => {
              setContributors(contributors.filter(({ selected }) => !selected));
              setContributorsChanged(true);
            }}
            sx={{ marginBottom: 1 }}
          >
            Remove selected from episode
          </Button>
        </div>
        {contributors.length ? (
          <>
            <SelectableContributors
              contributors={contributors}
              disabled={!contributorsData?.canEditContributors}
              onChange={(
                newContributor: ContributorProps,
                index: number,
                changedAttribute,
              ) => {
                if (changedAttribute !== "selected") {
                  setActionBarVisibility(newContributor);
                }

                updateContributor(newContributor, index);
              }}
              onDelete={(contributorToDelete: ContributorProps) => {
                handleContributorsChange(
                  contributors.filter(
                    (contributor) => contributor !== contributorToDelete,
                  ),
                );
                setContributorsChanged(true);
              }}
              onInternalChange={(newContributor: ContributorProps) => {
                setActionBarVisibility(newContributor);
              }}
            />
            <Box justifyContent="center" justifyItems="center" display="flex">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Add />}
                onClick={handleOnAdd}
                sx={{ marginTop: 2 }}
              >
                Add new contributor
              </Button>
            </Box>
          </>
        ) : (
          <NoContributors
            onAdd={handleOnAdd}
            loading={isContributorsDataLoading}
          />
        )}
      </Paper>
      {contributorsData?.canEditContributors && (
        <Paper
          sx={{ marginBottom: 2, borderRadius: 2, padding: 2, boxShadow: 6 }}
        >
          {inModal && <Divider />}
          <Typography variant="h5" marginBottom={2}>
            Contributors present elsewhere in this series
          </Typography>
          <div>
            <Button
              variant="contained"
              color="secondary"
              disabled={!hasSelectedContributors(seriesContributors)}
              onClick={() => {
                const selectSeriesContributors: ContributorProps[] =
                  seriesContributors.filter(
                    (contributor) => contributor.selected,
                  );

                if (selectSeriesContributors.length) {
                  const contributorIds = contributors.map(getCreditId);
                  const extraContributors = selectSeriesContributors
                    .filter(
                      (contributor) =>
                        !contributorIds.includes(getCreditId(contributor)),
                    )
                    .map((contributor: ContributorProps) => ({
                      ...contributor,
                      selected: false,
                    }));

                  if (extraContributors.length) {
                    setElsewhereContributors([
                      ...contributors,
                      ...extraContributors,
                    ]);
                    handleContributorsChange([
                      ...contributors,
                      ...extraContributors,
                    ]);
                    setContributorsChanged(true);
                  }
                }
              }}
            >
              Add selected to episode
            </Button>
          </div>
          {loadingSeriesContributorData ? (
            <LinearProgress />
          ) : (
            <ContributorsTable
              displayCheckbox={true}
              contributors={seriesContributors}
              onChange={(ids) => {
                const newContributors = clone(seriesContributors);
                newContributors.forEach((contributor: ContributorProps) => {
                  contributor.selected = ids.includes(getCreditId(contributor));
                });

                setSeriesContributors(newContributors);
              }}
            />
          )}
        </Paper>
      )}

      {contributorsChanged && !inModal && (
        <ActionBar
          cancelCallback={() => {
            setContributors(clone(initialContributors.current));
            setContributorsChanged(false);

            if (inModal) {
              NotificationService.pub(CLOSE);
            }
          }}
          spaceBetween
          width="100%"
          cancelDisabled={updatingContributors || isSaving}
          cancelLabel="Discard Changes"
          confirmLabel="Submit Contributor Form"
          confirmLoading={updatingContributors || isSaving}
          confirmCallback={() => {
            if (inModal) {
              setIsSaving(true);
              NotificationService.pub(FINISH_CONTRIBUTORS_FORM);
            } else {
              mutate({});
            }
          }}
        />
      )}
    </Container>
  );
};

export const TitleContributorsWithData: FC = () => {
  const { titleCcid } = useParams<string>();
  const setElseWhereContributors = () => {
    return undefined;
  };
  const { data: contributorData, isLoading: isContributorsDataLoading } =
    useContributors(titleCcid);

  return (
    <TitleContributors
      setElsewhereContributors={setElseWhereContributors}
      contributorsData={
        contributorData || { contributors: [], canEditContributors: false }
      }
      isContributorsDataLoading={isContributorsDataLoading}
    />
  );
};
