import { useState, useEffect } from "react";
import * as Yup from "yup";
import {
  Container,
  Typography,
  Stack,
  CircularProgress,
  Alert,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { Box } from "@mui/system";
import { useParams, Link as RouterLink } from "react-router-dom";
import { FormHeader } from "../../components";
import { useCloseDown } from "../../hooks/queries/useCloseDown";
import { Form, Formik, useFormikContext } from "formik";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckIcon from "@mui/icons-material/Check";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { BudgetPage } from "./BudgetPage";
import { ProductionPage } from "./ProductionPage";
import { RiskPage } from "./RiskPage";
import { SustainabilityPage } from "./SustainabilityPage";
import { RetentionPage } from "./RetentionPage";
import { SERIES, TITLES } from "../../constants";

const iconSize = {
  width: "1rem",
  height: "1rem",
  position: "relative",
  margin: 1,
};

type StepId =
  | "production"
  | "budget"
  | "riskLearnings"
  | "sustainability"
  | "retention";

interface FormStep {
  id: StepId;
  title: string;
}

const STEPS: Array<FormStep> = [
  {
    id: "production",
    title: "Production",
  },
  {
    id: "budget",
    title: "Budget & Schedule Learnings",
  },
  {
    id: "riskLearnings",
    title: "Risk Learnings",
  },
  {
    id: "sustainability",
    title: "Sustainability",
  },
  {
    id: "retention",
    title: "Retention",
  },
];

const closeDownFormSchema = Yup.object({
  production: Yup.object({
    closeDownDate: Yup.string(),
    productionTeam: Yup.string(),
    productionStatus: Yup.string().oneOf(
      [
        "Development",
        "Pre Production",
        "Production",
        "Post Production",
        "Closed",
        "Paused",
        "Cancelled",
      ],
      "Invalid production status",
    ),
    technicalWorkflow: Yup.string(),
    locations: Yup.string(),
  }),
  budget: Yup.object({
    design: Yup.string(),
    finalCost: Yup.string(),
    procurement: Yup.string(),
    keyResources: Yup.string(),
    onScreenTalent: Yup.string(),
    rights: Yup.string(),
  }),
  riskLearnings: Yup.object({
    hAndS: Yup.string(),
    dutyOfCare: Yup.string(),
    cyberSecurity: Yup.boolean(),
    compliance: Yup.string(),
    insurance: Yup.string(),
  }),
  sustainability: Yup.object({
    finalCarbonAlbert: Yup.boolean(),
    offsetAlbert: Yup.boolean(),
    greenTeam: Yup.boolean(),
  }),
  retention: Yup.object({
    wipAndRetentionGrid: Yup.string(),
  }),
});

const closeDownFormInitialData: CloseDownFormValues = {
  production: {
    closeDownDate: "",
    productionTeam: "",
    productionStatus: undefined,
    technicalWorkflow: "",
    locations: "",
  },
  budget: {
    design: "",
    finalCost: "",
    procurement: "",
    keyResources: "",
    onScreenTalent: "",
    rights: "",
  },
  riskLearnings: {
    hAndS: "",
    dutyOfCare: "",
    cyberSecurity: false,
    compliance: "",
    insurance: "",
  },
  sustainability: {
    finalCarbonAlbert: false,
    offsetAlbert: false,
    greenTeam: false,
  },
  retention: {
    wipAndRetentionGrid: "",
  },
};

export type CloseDownFormValues = Yup.InferType<typeof closeDownFormSchema>;

interface CloseDownFormNewProps {
  level: "series" | "titles";
}

export function CloseDownFormNew({ level }: CloseDownFormNewProps) {
  const { seriesCcid, titleCcid, programmeCcid } = useParams();
  const ccid = level === "series" ? seriesCcid : titleCcid;

  const type = titleCcid ? TITLES : SERIES;

  const taskPageUrl =
    level === "titles"
      ? `/special/${titleCcid}`
      : `/programmes/${programmeCcid}/series/${seriesCcid}`;

  const { data: closeDownFormData, isLoading: loadingFormData } = useCloseDown({
    ccid,
    type: level,
  });

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const currentStep = STEPS[currentStepIndex];
  const stepNumber = currentStepIndex + 1;

  const isLoading = loadingFormData;

  const [formData, setFormData] = useState<CloseDownFormValues>(
    closeDownFormInitialData,
  );

  useEffect(() => {
    if (closeDownFormData) {
      const newFormData = {
        production:
          closeDownFormData.production || closeDownFormInitialData.production,
        budget: closeDownFormData.budget || closeDownFormInitialData.budget,
        riskLearnings:
          closeDownFormData.riskLearnings ||
          closeDownFormInitialData.riskLearnings,
        retention:
          closeDownFormData.retention || closeDownFormInitialData.retention,
        sustainability:
          closeDownFormData.sustainability ||
          closeDownFormInitialData.sustainability,
      };
      setFormData(newFormData);
    }
  }, [closeDownFormData]);

  if (isLoading) {
    return (
      <>
        <FormHeader formTitle="Close-down Form" />
        <Stack alignItems="center" m={4}>
          <CircularProgress />
        </Stack>
      </>
    );
  }

  if (!closeDownFormData) {
    return (
      <>
        <FormHeader formTitle="Close-down Form" />
        <Stack alignItems="center" m={4}>
          <Alert severity="error">
            There was an issue retrieving the data for this form. Please try
            refreshing or contact us if the issue persists
          </Alert>
        </Stack>
      </>
    );
  }

  const renderFormStepContent = () => {
    switch (currentStep.id) {
      case "production":
        return <ProductionPage ccid={ccid as string} type={type} />;
      case "budget":
        return <BudgetPage ccid={ccid as string} type={type} />;
      case "riskLearnings":
        return <RiskPage ccid={ccid as string} type={type} />;
      case "sustainability":
        return <SustainabilityPage ccid={ccid as string} type={type} />;
      case "retention":
        return <RetentionPage ccid={ccid as string} type={type} />;
      default:
        return null;
    }
  };

  return (
    <>
      <FormHeader formTitle="Close-down Form" />
      <Stack alignItems="center" m={4}>
        <Container disableGutters>
          <Formik
            initialValues={formData}
            enableReinitialize
            validationSchema={closeDownFormSchema}
            validateOnChange={false}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {() => {
              return (
                <>
                  <Form noValidate>
                    <Stack alignItems="left" spacing={5} direction="row">
                      <Box sx={{ maxWidth: "100%", p: 1, position: "sticky" }}>
                        <FormSteps
                          currentStepIndex={currentStepIndex}
                          handleStepChange={setCurrentStepIndex}
                          taskPageUrl={taskPageUrl}
                          // TODO: get autosave date
                          autosaveDate={undefined}
                        />
                      </Box>
                      <Box width="100%">
                        <Stack spacing={2}>
                          <Typography variant="h5">
                            {`Section ${stepNumber} of ${STEPS.length}: ${currentStep.title}`}
                          </Typography>

                          {renderFormStepContent()}
                        </Stack>
                      </Box>
                    </Stack>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Container>
      </Stack>
    </>
  );
}

function FormSteps({
  currentStepIndex,
  handleStepChange,
  autosaveDate,
  taskPageUrl,
}: {
  currentStepIndex: number;
  handleStepChange: (stepIndex: number) => void;
  autosaveDate: string | undefined;
  taskPageUrl: string;
}) {
  const formik = useFormikContext<CloseDownFormValues>();
  const [open, setOpen] = useState(false);
  return (
    <Stack spacing={2}>
      <Timeline sx={{ width: "270px", justifyContent: "space-between" }}>
        {STEPS.map((step, index) => {
          const stepNumber = index + 1;
          const errorFields = Object.keys(formik.errors);
          const hasErrors = errorFields.includes(step.id);
          const shouldDisplayErrors = formik.submitCount > 0;
          const isLastStep = index === STEPS.length - 1;

          return (
            <TimelineItem key={`step-${step.id}`}>
              <TimelineOppositeContent sx={{ display: "none" }} />
              <TimelineSeparator>
                {index === currentStepIndex ? (
                  <CircleIcon sx={{ ...iconSize }} />
                ) : (
                  <CircleOutlinedIcon sx={{ ...iconSize }} />
                )}
                {isLastStep ? <></> : <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent sx={{ paddingTop: 0 }}>
                <Button
                  type="button"
                  onClick={() => handleStepChange(index)}
                  variant="text"
                  sx={{ textAlign: "left", borderRadius: 0, padding: "4px 0" }}
                >
                  <Typography
                    color={
                      shouldDisplayErrors && hasErrors ? "error" : "primary"
                    }
                    component="span"
                  >
                    {stepNumber}. {step.title}
                  </Typography>
                </Button>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>

      <Stack spacing={1} direction="row">
        <IconButton
          aria-label="Previous section"
          disabled={currentStepIndex === 0}
          color="primary"
          onClick={() => {
            const newStepIndex = Math.max(0, currentStepIndex - 1);
            handleStepChange(newStepIndex);
          }}
        >
          <ArrowBack />
        </IconButton>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={() => {
            const newStepIndex = Math.min(
              STEPS.length - 1,
              currentStepIndex + 1,
            );
            handleStepChange(newStepIndex);
          }}
          disabled={currentStepIndex === STEPS.length - 1}
          sx={{ fontWeight: "light" }}
          endIcon={<ArrowForward />}
        >
          Next Section
        </Button>
      </Stack>

      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        size="large"
        color="primary"
      >
        Save Form
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {"Your Answers are Saved"} <CheckIcon />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your answers have been saved you can now continue editing or return
            to the tasks page
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            component={RouterLink}
            to={taskPageUrl}
            variant="contained"
            size="large"
            color="primary"
          >
            Return to Task Page
          </Button>
          <Button onClick={() => setOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
