import { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Card,
  CardContent,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormikContext } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CloseDownFormValues } from "./CloseDownFormNew";
import { FormFieldLabel } from "../../componentsV2/FormFieldLabel";
import dayjs from "dayjs";
import { useUpdateCloseDown } from "../../hooks/mutations/useUpdateCloseDown";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

const now = dayjs();

const productionOptions: Array<{ label: string; value: string }> = [
  { value: "Development", label: "Development" },
  { value: "Pre Production", label: "Pre Production" },
  { value: "Production", label: "Production" },
  { value: "Post Production", label: "Post Production" },
  { value: "Closed", label: "Closed" },
  { value: "Paused", label: "Paused" },
  { value: "Cancelled", label: "Cancelled" },
];

interface PageProps {
  ccid: string;
  type: "series" | "titles";
}

export function ProductionPage({ ccid, type }: PageProps) {
  const form = useFormikContext<CloseDownFormValues>();
  const formValues = form.values.production;
  const [closeDownDateValue, setCloseDownDateValue] = useState("");
  const [productionStatusValue, setProductionStatusValue] = useState(
    formValues.productionStatus || "Please Select Production Status",
  );

  useEffect(() => {
    if (formValues && formValues.closeDownDate) {
      setCloseDownDateValue(formValues?.closeDownDate);
    }
    if (formValues && formValues.productionStatus) {
      setProductionStatusValue(formValues?.productionStatus);
    }
  }, [formValues]);

  const { mutate: updateCloseDown } = useUpdateCloseDown(
    () => {
      enqueueSnackbar("Content Autosaved", { variant: "success" });
    },
    () => {
      enqueueSnackbar("There is an error on saving the form data", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  const autosaveForm = (formField: string, input: string) => {
    const changes = {
      production: {
        [formField]: input,
      },
    };
    updateCloseDown({ ccid, formData: changes, type });
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Production</Typography>
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="space-bewteen"
                  spacing={18}
                >
                  <FormFieldLabel
                    inputId="production.closeDownDate"
                    label="Close Down Date"
                    required={false}
                  >
                    <DatePicker
                      name="closeDownDate"
                      onChange={(newValue) => {
                        const formattedValue = newValue
                          ? newValue.format("YYYY-MM-DD")
                          : "";
                        setCloseDownDateValue(formattedValue);
                        form.setFieldValue(
                          "production.closeDownDate",
                          formattedValue,
                        );
                        autosaveForm("closeDownDate", formattedValue);
                      }}
                      value={dayjs(closeDownDateValue) || now}
                      slotProps={{
                        textField: {
                          id: "closeDownDate",
                          required: false,
                        },
                      }}
                    />
                  </FormFieldLabel>

                  <FormFieldLabel
                    inputId="production.productionStatus"
                    label="Production Status"
                    required={false}
                  >
                    <Select
                      id="production.productionStatus"
                      sx={{ width: 400 }}
                      value={productionStatusValue}
                      onChange={(event) => {
                        console.log(event.target.value);
                        form.handleChange(event);
                        setProductionStatusValue(event.target.value);
                        autosaveForm("productionStatus", event.target.value);
                      }}
                      onBlur={form.handleBlur}
                    >
                      {productionOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormFieldLabel>
                </Stack>
                <Typography>
                  Production Team Discussion Point: Any relevant notes from
                  Talent Team wrap chat? *For more information please speak to
                  HOP / Talent Team contact as detailed above.
                </Typography>
                <TextField
                  value={formValues.productionTeam || undefined}
                  id="production.productionTeam"
                  multiline
                  fullWidth
                  required
                  placeholder="Outline of key personnel Roles/Responsibilities. Was the team (Editorial, Production Management and Technical) structure and responsibilities fit for purpose?"
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("productionTeam", event.target.value);
                  }}
                />
              </Stack>
              <Stack>
                <Typography>
                  Technical Workflow (capture to delivery)
                </Typography>
                <TextField
                  value={formValues.technicalWorkflow || undefined}
                  id="production.technicalWorkflow"
                  multiline
                  placeholder="Was this fit for purpose?"
                  fullWidth
                  required
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("technicalWorkflow", event.target.value);
                  }}
                />
              </Stack>
              <Stack>
                <Typography>Location(s)</Typography>
                <TextField
                  value={formValues.locations || undefined}
                  id="production.locations"
                  multiline
                  fullWidth
                  placeholder="Did the show film in any external locations? Were they suitable ? Any issues?"
                  required
                  maxRows={8}
                  minRows={8}
                  onChange={(event) => {
                    form.handleChange(event);
                  }}
                  onBlur={(event) => {
                    form.handleBlur(event);
                    autosaveForm("locations", event.target.value);
                  }}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
